/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  width: 93.48958333%;
}
.section.section--footer .services > .meta {
  display: block;
  float: left;
  border-right: 1px solid #000;
  padding: 0 4px;
}
.section.section--footer .services > .meta:first-child {
  padding-left: 0;
}
.section.section--footer .services > .meta:last-child {
  border-right: none;
}
.cb-goto-top {
  right: 3.25520833%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 2.00534759%;
  margin-left: 2.00534759%;
}
.area .part {
  margin-right: 2.00534759%;
  margin-left: 2.00534759%;
  width: 95.98930481%;
}
.area .tiny {
  width: 45.98930481%;
}
.area > .slim {
  width: 50%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 4.01069519%;
  margin-left: 4.01069519%;
}
.area > .slim .part {
  width: 91.97860963%;
}
.area > .slim .tiny {
  width: 91.97860963%;
}
.area {
  width: 104.17827298%;
  margin-left: -2.08913649%;
  margin-right: -2.08913649%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.cb-layout3 .area.main {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.cb-layout3 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout3 .area.main .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main > .slim .part {
  width: 100%;
}
.part {
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-layout1 .part.pict img.cb-portrait {
  width: 77.29805014%;
}
.base > .wide.form .tile .name,
.main > .wide.form .tile .name,
.base > .wide a.capt,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.base > .wide.form .tile .chop,
.main > .wide.form .tile .chop,
.base > .wide.form .tile textarea,
.main > .wide.form .tile textarea,
.base > .wide.form .tile input.text,
.main > .wide.form .tile input.text,
.base > .wide.form .tile .ctrl,
.main > .wide.form .tile .ctrl,
.base > .wide input.capt,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */